<template>
  <skeleton-raw-materials-detail-customer-component v-if="bSkeleton" />
  <div class="content-raw-material-detail-customer" v-else>
    <filter-raw-materials-detail-customer-component
      :bAdminCustomer="bAdminCustomer"
      @setSearch="setSearch"
    />
    <no-data-text-component-global
      :iHeight="0"
      :sTextEmpty="sTextEmpty"
      v-if="bEmptyTable"
    />
    <v-data-table
      v-else
      v-show="!isMobile"
      mobile-breakpoint="0"
      :headers="headers"
      :disable-sort="true"
      :items="aTable"
      :loading="bLoadingTable"
      loading-text="Cargando datos..."
      :items-per-page="iItemsPerPage"
      :hide-default-footer="true"
      no-data-text="No hay información disponible para mostrar."
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }"
      class="global-table"
    >
      <template slot="progress">
        <v-progress-linear
          color="var(--primary-color-loading-table)"
          indeterminate
        ></v-progress-linear>
      </template>
      <template v-slot:[`header.sColorKey`]>
        <!-- <v-checkbox v-model="bResetPriceAll" :indeterminate="setModIndeterminate()" @change="getRawMaterialsResetPriceAll()"
          class="global-checkbox global-checkbox-table" color="var(--primary-color-text-yellow)">
        </v-checkbox> -->
      </template>
      <template v-slot:[`item.sColorKey`]="{ item }">
        <div v-if="bAdminCustomer" class="content-color-id-raw-material">
          <v-checkbox
            v-model="item.bRestPrice"
            @change="
              getRawMaterialsResetPrice(item.bRestPrice, item.sRawMaterialId)
            "
            class="global-checkbox global-checkbox-table"
            :class="
              item.bRestPrice
                ? 'global-checkbox-table-yellow'
                : 'global-checkbox-table-white-or-black'
            "
            color="var(--primary-color-text-yellow)"
          >
          </v-checkbox>
          <div
            :style="{
              'background-color': '#' + item.sColorKey + '!important',
            }"
            class="color-raw-material"
          ></div>
        </div>
      </template>
      <template v-slot:[`item.dBaseSalePrice`]="{ item }">
        {{ item.dBaseSalePrice }}
      </template>
      <template v-slot:[`item.dUnitPrice`]="{ item }">
        {{ item.dUnitPrice }}
      </template>
      <template v-slot:[`item.accions`]="{ item }">
        <v-tooltip top content-class="global-tooltip-black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="bAdminCustomer"
              v-bind="attrs"
              v-on="on"
              icon
              @click="setDialogUpdatePriceRawMaterial(item)"
              class="global-icon-action"
            >
              <span class="material-icons"> currency_exchange </span>
            </v-btn>
          </template>
          <span>Actualizar precio</span>
        </v-tooltip>
      </template>
      <template v-slot:footer>
        <footer-table-component-global
          :numPages="numPages"
          :totalItems="totalItems"
          @changeTable="getEmitRawMaterial"
        />
      </template>
    </v-data-table>
    <table-mobile-raw-materials-detail-customer-component
      v-show="isMobile"
      @changeTable="getEmitRawMaterial"
      @openDetailItem="setDialogUpdatePriceRawMaterial"
      @openDeleteItem="deleteItem"
      @openUpdatePrice="setDialogUpdatePriceRawMaterial"
      :aTable="aTable"
      :headers="headers"
      :numPages="numPages"
      :totalItems="totalItems"
      :bChangeSelectedItem="bChangeSelectedItem"
      :bAdminCustomer="bAdminCustomer"
    />
    <dialog-update-price-raw-material-global
      :bDialogUpdatePriceRawMaterial="bDialogUpdatePriceRawMaterial"
      @setDialogUpdatePriceRawMaterial="setDialogUpdatePriceRawMaterial"
      :sApi="sApiUpdatePrice"
      :dBasePriceMXN="dBaseSalePriceMXN"
      :dBasePriceUSD="dBaseSalePriceUSD"
      :dUnitPriceMXN="dUnitPriceMXN"
      :dUnitPriceUSD="dUnitPriceUSD"
      :itemRawMaterial="itemRawMaterial"
      sLabelBasePrice="Precio base de venta"
      sLabelCurrentCustomPrice="Precio personalizado actual de venta"
    />
  </div>
</template>

<script>
export default {
  props: {
    iTab: Number,
    sIdCustomer: String,
  },
  data() {
    return {
      emptyData: "Sin información.",
      bSkeleton: true,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      sSearch: "",
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      headers: [],
      aTable: [],
      isMobile: false,
      bChangeSelectedItem: false,
      itemRawMaterial: {},
      dBaseSalePrice: 0,
      dBaseSalePriceMXN: null,
      dBaseSalePriceUSD: null,
      dUnitPrice: 0,
      dUnitPriceMXN: null,
      dUnitPriceUSD: null,
      sApiUpdatePrice: "",
      bDialogUpdatePriceRawMaterial: false,
      sTextEmpty: "",
      bResetPriceAll: false,
    };
  },
  beforeMount() {
    if (this.iTab === 2) {
      this.setHeadersTable();
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          this.setTextEmpty();
          this.getRawMaterials();
        })
        .catch((err) => {
          this.Error(err);
        });
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.$store.commit("setEmptyRawMaterials");
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    getEmitRawMaterial(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getRawMaterials();
    },
    getRawMaterialsResetPriceAll() {
      if (this.aTable.filter((e) => e.bRestPrice === true).length > 0) {
      } else {
      }
    },
    getRawMaterialsResetPrice(bResetPrice, sRawMaterialId) {
      if (bResetPrice) {
        this.$store.commit("setPushRawMateriasResetPrice", sRawMaterialId);
      } else {
        this.$store.commit("setRemoveRawMateriasResetPrice", sRawMaterialId);
      }
    },
    findRawMaterialsResetPrice(sRawMaterialId) {
      return this.aRawMaterialsResetPrice.includes(sRawMaterialId);
    },
    findSelectedRawMaterial() {
      return this.aTable.filter((e) => e.bRestPrice === true).length > 0;
    },
    setModIndeterminate() {
      return this.aTable.filter((e) => e.bRestPrice === true).length > 0;
    },
    getRawMaterials() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          setTimeout(() => {
            DB.get(`${URI}/api/sp/v1/customers/${this.sIdCustomer}/materials`, {
              params: {
                iPageNumber: this.iCurrentPage,
                iItemsPerPage: this.iItemsPerPage,
                sSearch: this.sSearch,
              },
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            })
              .then((response) => {
                this.numPages = response.data.numPages;
                this.totalItems = response.data.total;
                this.aTable = response.data.results.map((e, i) => {
                  return {
                    ...e,
                    sRawMaterialId: e.sRawMaterialId,
                    bRestPrice: this.findRawMaterialsResetPrice(
                      e.sRawMaterialId
                    ),
                    sColorKey: e.sColorKey,
                    sCode: e.sCode,
                    sName: e.sName,
                    dBaseSalePriceMXN: e.oRawMaterialPrices.oMexicanPeso.dBasePrice,
                    dBaseSalePriceUSD: e.oRawMaterialPrices.oUSDollar.dBasePrice,
                    dBaseSalePrice:
                      e.oRawMaterialPrices.oMexicanPeso.dBasePrice +
                      " / " +
                      e.oRawMaterialPrices.oUSDollar.dBasePrice,

                    dUnitPriceMXN:
                      e.oRawMaterialPrices.oMexicanPeso.dCustomerPrice,
                    dUnitPriceUSD:
                      e.oRawMaterialPrices.oUSDollar.dCustomerPrice,
                    dUnitPrice:
                      e.oRawMaterialPrices.oMexicanPeso.dCustomerPrice +
                      " / " +
                      e.oRawMaterialPrices.oUSDollar.dCustomerPrice,
                  };
                });
                this.totalView = this.aTable.length;
                this.bLoadingTable = false;
                this.bSkeleton = false;
                this.bEmptyTable = this.aTable.length < 1;
                this.$store.commit("refresher", false);
              })
              .catch((error) => {
                this.Error(error.response.data);
              });
          }, 1000);
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    updatePrice(item) {},
    deleteItem(item) {},
    setHeadersTable() {
      let aHeaders = [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "sColorKey",
          class: "global-header-table thColor",
          cellClass: "global-body-table ",
        },
        {
          text: "Clave",
          sortable: false,
          value: "sCode",
          class: "global-header-table ",
          cellClass: "global-body-table w-100 ",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "sName",
          class: "global-header-table global-header-name",
          cellClass: "global-body-table global-body-name ",
        },
        {
          text: "Precio personalizado: Venta",
          sortable: false,
          value: "dUnitPrice",
          class: "global-header-table global-header-account-type",
          cellClass: "global-body-table",
        },
        {
          text: "Última modificación de precio: Venta",
          sortable: false,
          value: "tUpdatedAt",
          class: "global-header-table w-300",
          cellClass: "global-body-table  w-300",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class: `global-header-table ${
            this.bAdminCustomer ? "" : "d-none"
          }  global-header-accions  global-col-header-accion`,
          cellClass: `global-body-table  ${
            this.bAdminCustomer ? "" : "d-none"
          }  global-body-accions global-col-body-accion`,
        },
      ];
      this.headers = aHeaders;
    },
    detailRawMaterial(item) {
      this.$router
        .push({
          name: "RawMaterialDetail",
          params: { id: "f8ad4a66-8f57-44ab-a797-45481c38970b" },
        })
        .catch(() => {});
    },
    setSearch(val) {
      this.sSearch = val;
    },
    setDialogUpdatePriceRawMaterial(itemRawMaterial) {
      if (itemRawMaterial !== undefined) {
        this.itemRawMaterial = itemRawMaterial;
        this.dBaseSalePriceMXN = this.itemRawMaterial.dBaseSalePriceMXN;
        this.dBaseSalePriceUSD = this.itemRawMaterial.dBaseSalePriceUSD;
        this.dUnitPriceMXN = this.itemRawMaterial.dUnitPriceMXN;
        this.dUnitPriceUSD = this.itemRawMaterial.dUnitPriceUSD;
        this.sApiUpdatePrice = `${URI}/api/sp/v1/customers/${this.$route.params.id}/materials/${this.itemRawMaterial.sRawMaterialId}`;
      }
      this.bDialogUpdatePriceRawMaterial = !this.bDialogUpdatePriceRawMaterial;
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen materias primas registradas.</p>";
      this.sTextEmpty = sTextemptyTemp;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getRawMaterials();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    aRawMaterialsResetPrice() {
      return this.$store.state.aRawMaterialsResetPrice;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowCustomer() {
      return this.$store.state.bShowCustomer;
    },
    bAdminCustomer() {
      return this.$store.state.bAdminCustomer;
    },
  },
  watch: {
    aTable() {
      if (this.findSelectedRawMaterial()) {
      } else {
      }
    },
    bResetPriceAll() {
      if (this.bResetPriceAll) {
        if (this.setModIndeterminate()) {
        } else {
        }
      } else {
      }
    },
    refreshTable: function () {
      if (this.iTab === 2) {
        this.getRawMaterials();
      }
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getRawMaterials();
    },
    iCurrentPage: function () {
      this.getRawMaterials();
    },
    bAdminCustomer() {
      this.setHeadersTable();
    },
    iTab() {
      if (this.iTab === 2) {
        this.setHeadersTable();

        this.bLoadingTable = true;
        this.bChangeSelectedItem = !this.bChangeSelectedItem;
        this.aTable = [];
        this.getRawMaterials();
      } else {
        this.$store.commit("setEmptyRawMaterials");
      }
    },
  },
};
</script>

<style scoped>
.content-raw-material-detail-customer {
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0px 12px 0px 12px;
}

.content-color-id-raw-material {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-raw-material-detail-customer {
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 0px 10px 0px 10px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
